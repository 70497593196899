'use client'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

const gtmParams = {
  id: process.env.NEXT_PUBLIC_GTM_ID || '',
  customDomain: process.env.NEXT_PUBLIC_GTM_DOMAIN ? `https://${process.env.NEXT_PUBLIC_GTM_DOMAIN}` : undefined
}

type Props = {
  children?: React.ReactNode
}

const Analytics = ({ children }: Props) => {
  return <GTMProvider state={gtmParams}>{children}</GTMProvider>
}

export default Analytics
