'use client'
import { clsx } from 'common/utils/clsx'
import { X } from 'common/components/Icons'
import { useState, useEffect } from 'react'
import useFetch from 'common/hooks/useFetch'
import { dateIsPassed } from '../../../../utils/date'
import { IN_BROWSER } from '../../../../utils'

type Notification = {
  notification_email: string
  notification_text: string
  notify_from: Date | null
  notify_to: Date | null
  notification_status: number
}

const HeaderInfo = () => {
  const { fetch: fetchNotifications } = useFetch<Notification>(
    'api.site-notification',
  )

  const [isClosed, setIsClosed] = useState(false)
  const [notification, setNotification] = useState<Notification>()
  const [notificationValid, setNotificationValid] = useState(false)

  useEffect(() => {
    fetchNotifications().then((res) => {
      setNotification(res)
      if (
          res?.notification_status === 1 &&
          dateIsPassed(res?.notify_from) &&
          !dateIsPassed(res?.notify_to)
      ) {
        setNotificationValid(true)
      }
    })
    if (IN_BROWSER) {
      if (localStorage.getItem('headerClosed') === 'true') {
        const closedAtDate = new Date(localStorage.getItem('headerClosedAt') ?? '1970-01-01');

        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

        setIsClosed(closedAtDate > twentyFourHoursAgo);
      } else {
        setIsClosed(false)
      }
    } else {
      setIsClosed(false)
    }
  }, [])

  const closeHeader = () => {
    if (IN_BROWSER) {
      localStorage.setItem('headerClosed', 'true')
      localStorage.setItem('headerClosedAt', (new Date().toISOString()))
    }
    setIsClosed(true)
  }

  return (
    notificationValid && (
      <>
        <div
          className={clsx(
            'bg-[#dd3444] text-white text-xs px-2 py-1 justify-center header-info text-center items-center flex',
            isClosed && 'hidden',
            `${!isClosed && 'header-info-bar'}`,
          )}
        >
          {/* html notification text */}
          <div
            className="flex-1 mr-3"
            dangerouslySetInnerHTML={{
              __html: notification?.notification_text,
            }}
          ></div>
          <X onClick={() => closeHeader()} className="cursor-pointer" />
        </div>
      </>
    )
  )
}

export default HeaderInfo
