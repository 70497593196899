'use client'
import Divider from '@licommon/components/Divider'
import {FacebookLogo, InstagramLogo, PinterestLogo,} from '@licommon/components/Icons'
import {Site} from '@licommon/types/Site'
import {clsx} from '@licommon/utils/clsx'
import {getPortalType} from '@licommon/utils/portal'
import Link from 'next/link'
import {useRouter} from 'next/navigation'

export type FooterCopyrightProps = {
    companyName: string
    copyRightClassName?: string
    copyRightTextClassName?: string
    showFollowUsLinks?: boolean
    showDividers?: boolean
    facebookLink?: string
    instagramLink?: string
    siteDetails: Site
    backgroundColor: string
}
const FooterCopyright = ({
                             companyName,
                             copyRightClassName,
                             copyRightTextClassName,
                             showFollowUsLinks,
                             showDividers,
                             siteDetails,
                             backgroundColor,
                         }: FooterCopyrightProps) => {
    const router = useRouter()
    const {isCALIBO} = getPortalType()

    return (
        <div
            className={clsx(copyRightClassName, 'p-3 py-4')}
            style={{backgroundColor: backgroundColor}}
        >
            <div
                className={clsx(
                    showFollowUsLinks &&
                    'flex justify-between lg:flex-row flex-col gap-4',
                    !showFollowUsLinks && 'flex-col sm:flex-row',
                    'max-w-content mx-auto ',
                )}
            >
                <div
                    className={clsx(
                        copyRightTextClassName,
                        'items-center flex gap-1 md:gap-4 ',
                    )}
                >
                    <div
                        className={isCALIBO ? 'copyright-year' : 'text-center sm:text-left'}
                    >
                        © Copyright {new Date().getFullYear()} {companyName}
                    </div>
                    <div className="flex justify-center gap-2 md:gap-4 sm:justify-end mt-4 sm:mt-0">
                        {showDividers && <Divider className="border-l hidden lg:block"/>}
                        <Link
                            className="underline underline-offset-2 opacity-70 text-sm text-center"
                            href="/privacy-policy"
                            onClick={() => router.refresh()}
                        >
                            Privacy Policy
                        </Link>
                        {showDividers && <Divider className="border-l"/>}
                        <Link
                            className="underline underline-offset-2 opacity-70 text-sm text-center"
                            href="/terms-and-conditions"
                            onClick={() => router.refresh()}
                        >
                            Terms & Conditions
                        </Link>
                        {isCALIBO && (
                            <Link
                                className="underline underline-offset-2 opacity-70 text-sm text-center"
                                href="/warranty-policy"
                                onClick={() => router.refresh()}
                            >
                                Warranty Policy
                            </Link>
                        )}
                    </div>
                </div>
                {showFollowUsLinks && (
                    <div
                        className={
                            isCALIBO
                                ? 'socials'
                                : 'flex gap-2 items-center self-center mt-5 md:mt-0'
                        }
                    >
                        <span>Follow us </span>
                        {siteDetails.facebook && (
                            <Link
                                href={siteDetails.facebook}
                                target="__blank"
                                className="social-icon rounded-lg"
                                aria-label="Follow us on Facebook"
                            >
                                <FacebookLogo height={20} width={20}/>
                            </Link>
                        )}
                        {siteDetails.instagram && (
                            <Link
                                href={siteDetails.instagram}
                                target="__blank"
                                className="social-icon rounded-lg"
                                aria-label="Follow us on Instagram"
                            >
                                <InstagramLogo height={20} width={20}/>
                            </Link>
                        )}
                        {siteDetails.pinterest && (
                            <Link
                                href={siteDetails.pinterest}
                                target="__blank"
                                className="social-icon rounded-lg"
                                aria-label="Follow us on Pinterest"
                            >
                                <PinterestLogo height={20} width={20}/>
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default FooterCopyright
