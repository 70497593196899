'use client'

import { Menu } from '@headlessui/react'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import WithTransition from '../../../../../components/hoc/WithTransition'
import { Models } from '../../../../../types/Models'
import { getPortalType } from '../../../../../utils/portal'
import { CaretDown } from '../../../../Icons'
import MenuItemCTA from './Items/MenuItemCTA'
import MenuItemHeader from './Items/MenuItemHeader'
import MenuItemLink from './Items/MenuItemLink'
import MenuItems = Models.MenuItems
import MenuItem = Models.MenuItem

type HeaderMegaMenuProps = {
  show: boolean
  onClose?: Function
  items: MenuItems
}

const HeaderMegaMenu = ({ items, onClose, show }: HeaderMegaMenuProps) => {
  const [activeItem, setActiveItem] = useState<null | MenuItem>(null)
  const [activeSubItem, setActiveSubItem] = useState<null | MenuItem>(null)
  const { isCALIBO, isCFD } = getPortalType()
  const classPrefix = isCALIBO ? 'calibo_' : isCFD ? 'cfd_' : 'li_'

  const menuRefOne = useRef(null)
  const menuRefTwo = useRef(null)
  const menuRefThree = useRef(null)

  const findItemDepth = (pred: any): number => {
    const find = (items: MenuItems, depth: number = 0): number => {
      for (const item of items) {
        if (pred(item)) {
          return depth
        }
        const childDepth = find(item.children, depth + 1)
        if (childDepth !== -1) {
          return childDepth
        }
      }
      return -1
    }

    return find(items)
  }

  const handleActiveChildItemChange = (item: MenuItem, active: boolean) => {
    const depth = findItemDepth((active: MenuItem) => active.id === item.id)
    if (active) {
      if (depth == 0) {
        setActiveItem(item)
        setActiveSubItem(null)
      } else if (depth === 1) {
        setActiveSubItem(item)
      }
    } else {
      //setActiveItem(null);
      //setActiveSubItem(null);
    }
  }

  const renderMenuItem = (item: MenuItem) => {
    switch (item.type) {
      case 'text:header':
        return (
          <MenuItemHeader
            item={item}
            name={item.name}
            key={item.id}
            classNames={`${classPrefix}menu-item-header menu-item-header text-black-800`}
          />
        )
      case 'category':
        return (
          <MenuItemLink
            item={item}
            setActiveItem={handleActiveChildItemChange}
            key={item.id}
          />
        )
      case 'cta':
        return undefined
    }

    return (
      <MenuItemLink
        item={item}
        setActiveItem={handleActiveChildItemChange}
        key={item.id}
      />
    )
  }

  return (
    <div
      className={`${classPrefix}mega-menu mega-menu mega-menu__wrapper absolute w-page left-0 z-50 top-11`}
    >
      <Menu>
        <WithTransition
          show={show}
          className="border border-gray-300 bg-white text-black rounded-lg cursor-auto mt-1"
        >
          <Menu.Items
            static
            className="mega-menu__items flex w-100 flex-col 2xl:flex-row"
          >
            <div className="flex mega-menu-level scroll-indicator-parent">
              <div ref={menuRefOne} className="dropdown-nav-col w-full">
              {items.map((item) => {
                  if(item?.linked && item?.linked?.disabled) return
                  if(((item?.children?.length ?? 0) > 0) && item?.children?.every((child) => child?.linked?.disabled)) return
                  return renderMenuItem(item)
                } )}
              </div>
              <ScrollIndicator
                item={activeItem}
                subItem={activeSubItem}
                key={1}
                parentRef={menuRefOne}
              />
            </div>
            <div className="flex mega-menu-level scroll-indicator-parent">
              <div ref={menuRefTwo} className="dropdown-nav-col w-full">
                {!!activeItem?.children?.length && !activeItem?.children?.every((child) => child?.linked?.disabled) && [
                  <MenuItemHeader
                    item={activeItem}
                    name={activeItem.name}
                    key={`${activeItem.id}-header`}
                    classNames={'text-black-800'}
                  />,
                  ...activeItem.children.map((item) => {
                     if(item?.linked && item?.linked?.disabled) return
                  //   if(((item?.children?.length ?? 0) > 0) && item?.children?.every((child) => child?.linked?.disabled)) return
                    return renderMenuItem(item)
                  }),
                ]}
              </div>
              <ScrollIndicator
                item={activeItem}
                subItem={activeSubItem}
                key={2}
                parentRef={menuRefTwo}
              />
            </div>
            <div className="flex mega-menu-level relative scroll-indicator-parent">
              <div ref={menuRefThree} className="dropdown-nav-col w-full">
                {!!activeSubItem?.children?.length && !activeSubItem?.children?.every((child) => child?.linked?.disabled) && [
                  <MenuItemHeader
                    item={activeSubItem}
                    name={activeSubItem.name}
                    key={`${activeSubItem.id}-header`}
                    classNames={'text-black-800'}
                  />,
                  ...activeSubItem.children.map((item) => {
                     if(item?.linked && item?.linked?.disabled) return
                    return renderMenuItem(item)
                  }),
                ]}
              </div>
              <ScrollIndicator
                item={activeItem}
                subItem={activeSubItem}
                key={3}
                parentRef={menuRefThree}
              />
            </div>
            <div className={'mega-menu-cta'}>
              {items
                //ctas will only render if there is an image  set
                .filter((item) => item.type === 'cta' && item.image)
                .map((item) => {
                  return (
                    <MenuItemCTA
                      item={item}
                      setActiveItem={() => {}}
                      key={item.id}
                    />
                  )
                })}
            </div>
          </Menu.Items>
        </WithTransition>
      </Menu>
    </div>
  )
}
function ScrollIndicator({
  parentRef,
  item,
  subItem,
}: {
  item: any
  subItem: any
  parentRef: MutableRefObject<any>
}) {
  const element = parentRef?.current
  const [scrollTop, setScrollTop] = useState(0)
  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    const wrapper = document.querySelector('.mega-menu')
    if (!element || !wrapper) return
    const handleScroll = () => {
      if (!element) return
      setScrollTop(element.scrollTop)
      setShowScroll(element.scrollHeight > element.clientHeight)
    }

    wrapper.addEventListener('mousemove', handleScroll)
    element.addEventListener('scroll', handleScroll)
    return () => {
      wrapper.removeEventListener('mousemove', handleScroll)
      element.removeEventListener('scroll', handleScroll)
    }
  }, [element, item, subItem])

  if (scrollTop > 0 || !showScroll) return null
  return (
    <div className="absolute w-full bottom-0 left-0 flex justify-center scroll-indicator">
      <button
        onClick={() => {
          if (!element) return
          element.scroll({
            top: element.scrollHeight + 70,
            behavior: 'smooth',
          })
          setShowScroll(false)
        }}
        className="down-arrow p-[5px] bg-white rounded-full border border-gray-300"
      >
        <CaretDown size={18} />
      </button>
    </div>
  )
}

export default HeaderMegaMenu
