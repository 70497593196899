'use client'

import {decodeCssString} from '@licommon/utils/css'
import Image from 'next/image'
import Link from 'next/link'
import {useCallback, useState} from 'react'
import {Models} from '../../../../../../types/Models'
import {CaretRight} from '../../../../../Icons'
import useSlugEncoder from '../../../hooks/useSlugEncoder'
import MenuItem from './MenuItem'

type HeaderMegaMenuProps = {
    item: Models.MenuItem
    setActiveItem?: (item: Models.MenuItem, active: boolean) => void
    showCaret?: boolean
    classNames?: string
}

const MenuItemLink = ({
                          item,
                          showCaret = true,
                          setActiveItem = () => {
                          },
                          classNames
                      }: HeaderMegaMenuProps) => {
    const [active, setActive] = useState(false)
    const encodeSlug = useSlugEncoder()
    const handleSetActive = useCallback(
        (active: boolean) => {
            setActiveItem(item, active)
        },
        [active],
    )

    return (
        <div
            key={item.id}
            className={`mega-menu__item  dropdown-nav-row flex-1 2xl:flex-none ${
                active ? 'mega-menu__item:active' : ''
            }`}
        >
            <MenuItem item={item}>
                <Link
                    href={encodeSlug(item)}
                    onMouseEnter={(event) => handleSetActive(true)}
                    onMouseLeave={() => handleSetActive(false)}
                    className="dropdown-nav-item item-link"
                    data-children={item.id}
                    style={{
                        ...decodeCssString(item.customisation.css as string),
                    }}
                >
                    <div className="flex items-center">
                        {item.image ? (
                            <Image
                                className="object-contain mr-3"
                                src={item.image.url}
                                height={22}
                                width={22}
                                alt={item.name}
                            />
                        ) : null}
                        <span  className={classNames ?? ''}>{item.name}</span>
                    </div>
                    <span>{!!item.children.length && showCaret && <CaretRight/>}</span>
                </Link>
            </MenuItem>
        </div>
    )
}

export default MenuItemLink
